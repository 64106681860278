export const getStatusDescription = (status: string) => {
  switch (status) {
    case 'archived':
      return 'Archived';
    case 'active':
      return 'In Review';
    case 'reviewed':
      return 'Reviewed';
    default:
      return '';
  }
};
