import { combineReducers } from 'redux';

// Front
import AdoptionAgreementReducer from './adoption-agreement/reducer';
import ForgotPasswordReducer from './auth/forgetpwd/reducer';
import LoginReducer from './auth/login/reducer';
import ProfileReducer from './auth/profile/reducer';
import AccountReducer from './auth/register/reducer';
import LayoutReducer from './layouts/reducer';

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Profile: ProfileReducer,
  ForgetPassword: ForgotPasswordReducer,
  Account: AccountReducer,
  AdoptionAgreement: AdoptionAgreementReducer,
});

export default rootReducer;
