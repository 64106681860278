import React, { useState } from 'react';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const AgreementToolbar = (props: any) => {
  const dispatch = useDispatch<any>();

  const AAProperties = createSelector(
    (state: any) => state.AdoptionAgreement,
    (AdoptionAgreement) => ({
      agreements: AdoptionAgreement.agreements,
      selectedAgreementIds: AdoptionAgreement.selectedAgreementIds,
    }),
  );

  const { selectedAgreementIds, agreements } = useSelector(AAProperties);

  const [more_Menu, setMore_Menu] = useState(false);

  return (
    <React.Fragment>
      <div className='p-3 agreements-toolbar pb-0'>
        <Row id='firstRow' className='justify-content-between'>
          <Col lg={12}>
            <div className='search-box mb-2'>
              <div className='position-relative'>
                <input
                  type='text'
                  className='form-control bg-light border-light rounded'
                  placeholder='Search...'
                  onChange={(e: any) => props.handleSearch(e.target.value)}
                />
                <i className='bx bx-search-alt search-icon'></i>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <div className='d-flex align-items-start gap-2'>
              <div className='btn-toolbar' role='toolbar' id='toolbar'>
                {selectedAgreementIds.length > 0 && (
                  <div>
                    <Button
                      className='m-1'
                      type='button'
                      color='primary'
                      onClick={() => props.handleArchive()}
                    >
                      Archive
                    </Button>
                  </div>
                )}

                {selectedAgreementIds.length === 2 && (
                  <div>
                    <Button
                      className='m-1'
                      type='button'
                      color='primary'
                      onClick={() => props.handleCompare()}
                    >
                      Compare Agreements
                    </Button>
                  </div>
                )}

                {
                  //disabling dropdown with multiple option for now.
                  false && selectedAgreementIds.length > 1 && (
                    <div>
                      <Button className='m-1' type='button' color='primary'>
                        Archive
                      </Button>

                      <Dropdown
                        isOpen={more_Menu}
                        toggle={() => {
                          setMore_Menu(!more_Menu);
                        }}
                        className='btn-group m-1'
                      >
                        <DropdownToggle className='btn btn-primary  dropdown-toggle' tag='div'>
                          More <i className='mdi mdi-dots-vertical ms-2' />
                        </DropdownToggle>
                        <DropdownMenu className='dropdown-menu-end'>
                          <DropdownItem to='#'>Mark as Unread</DropdownItem>
                          <DropdownItem to='#'>Mark as Important</DropdownItem>
                          <DropdownItem to='#'>Add to Tasks</DropdownItem>
                          <DropdownItem to='#'>Add Star</DropdownItem>
                          <DropdownItem to='#'>Mute</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AgreementToolbar;
