import { getFirebaseBackend } from '../../../firebase';
import { apiError, loginSuccess, logoutUserSuccess, resetLoginFlag } from './reducer';

export const loginuser = (user: any, history: any) => async (dispatch: any) => {
  try {
    let response: any;
    let fireBaseBackend = await getFirebaseBackend();

    response = await fireBaseBackend.loginUser(user.email, user.password);
    localStorage.setItem('authUser', JSON.stringify(response));
    dispatch(loginSuccess(response));

    history('/adoption-agreement');
  } catch (error) {
    // TODO: how to log error message?
    dispatch(apiError('Invalid credentials. \r\nTry again or contact PDAI support team.'));
  }
};

export const logoutUser = () => async (dispatch: any) => {
  try {
    localStorage.removeItem('authUser');

    const fireBaseBackend = getFirebaseBackend();
    const response = await fireBaseBackend.logout();
    dispatch(logoutUserSuccess(response));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginMsgFlag = () => {
  try {
    const response = resetLoginFlag();
    return response;
  } catch (error) {
    return error;
  }
};

export const socialLogin = (type: any, history: any) => async (dispatch: any) => {
  try {
    let response: any;

    const fireBaseBackend = getFirebaseBackend();
    response = fireBaseBackend.socialLoginUser(type);

    const socialdata = await response;
    if (socialdata) {
      sessionStorage.setItem('authUser', JSON.stringify(socialdata));
      dispatch(loginSuccess(socialdata));
      history('/adoption-agreement');
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};
