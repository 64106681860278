// ... (other Firebase imports)
import { FirebaseHelper } from './helpers/firebase_helper';

let _fireBaseBackend: FirebaseHelper | null = null;

const initFirebaseBackend = () => {
  if (!_fireBaseBackend) {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_APIKEY,
      authDomain: process.env.REACT_APP_AUTHDOMAIN,
      databaseURL: process.env.REACT_APP_DATABASEURL,
      projectId: process.env.REACT_APP_PROJECTID,
      storageBucket: process.env.REACT_APP_STORAGEBUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
      appId: process.env.REACT_APP_APPID,
      measurementId: process.env.REACT_APP_MEASUREMENTID,
    };
    _fireBaseBackend = new FirebaseHelper(firebaseConfig);
  }
  return _fireBaseBackend;
};

const getFirebaseBackend = (): FirebaseHelper => {
  if (!_fireBaseBackend) {
    throw new Error('FirebaseHelper not initialized. Call initFirebaseBackend first.');
  }
  return _fireBaseBackend;
};

export { getFirebaseBackend, initFirebaseBackend };
