import { createSlice } from '@reduxjs/toolkit';

import { AdoptionAgreement } from '@helpers/firebase_helper';
import {
  listAdoptionAgreementsByStatus,
  listAllAdoptionAgreementIds,
  listAllAdoptionAgreements,
  selectAdoptionAgreement,
  updateAdoptionAgreement,
} from './thunk';

interface AdoptionAgreementsState {
  agreements: AdoptionAgreement[];
  agreementIds: string[];
  selectedAgreementIds: string[];
  loading: boolean;
  error: string | null;
}

const initialState: AdoptionAgreementsState = {
  agreements: [],
  agreementIds: [],
  selectedAgreementIds: [],
  loading: false,
  error: null,
};

const AdoptionAgreementsSlice = createSlice({
  name: 'adoptionAgreements',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // List All Adoption Agreements
      .addCase(listAllAdoptionAgreements.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listAllAdoptionAgreements.fulfilled, (state, action) => {
        state.loading = false;
        state.agreements = action.payload;
        console.log('state.agreements');
        console.log(state.agreements);
      })
      .addCase(listAllAdoptionAgreements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error fetching adoption agreements';
      })

      // List All Adoption Agreement IDs
      .addCase(listAllAdoptionAgreementIds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listAllAdoptionAgreementIds.fulfilled, (state, action) => {
        state.loading = false;
        state.agreementIds = action.payload;
      })
      .addCase(listAllAdoptionAgreementIds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error fetching adoption agreement IDs';
      })

      // List Adoption Agreements By Status
      .addCase(listAdoptionAgreementsByStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listAdoptionAgreementsByStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.agreements = action.payload;
      })
      .addCase(listAdoptionAgreementsByStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error fetching adoption agreements by status';
      })

      .addCase(updateAdoptionAgreement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAdoptionAgreement.fulfilled, (state, action) => {
        console.log('reducer: ', action.payload);
        state.loading = false;
        state.agreements = [
          ...state.agreements.map((agreement) =>
            agreement.id === action.payload.id ? { ...agreement, ...action.payload } : agreement,
          ),
        ];
      })
      .addCase(updateAdoptionAgreement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error updating adoption agreement';
      })

      .addCase(selectAdoptionAgreement.fulfilled, (state, action) => {
        if (state.selectedAgreementIds.includes(action.payload)) {
          const index = state.selectedAgreementIds.indexOf(action.payload);
          state.selectedAgreementIds.splice(index, 1);
        } else {
          state.selectedAgreementIds.push(action.payload);
        }
      });
  },
});

export default AdoptionAgreementsSlice.reducer;
