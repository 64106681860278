import { ProvisionAnswer } from '@pdai/shared';
import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface ProvisionRowProps {
  provisionAnswer: ProvisionAnswer;
  onSave: (newValue: string) => void;
}

const ProvisionRow: React.FC<ProvisionRowProps> = ({ provisionAnswer, onSave }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [editedValue, setEditedValue] = useState(provisionAnswer.userAnswer || '');
  const originalValue = provisionAnswer.modelAnswer || '';

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleSave = () => {
    onSave(editedValue);
    toggleModal();
  };

  const handleCancel = () => {
    toggleModal();
  };

  const handleReset = () => {
    setEditedValue(originalValue);
  };

  return (
    <tr>
      <td className='px-5'>{provisionAnswer.provisionLabel}</td>
      <td className='answer-column'>
        <span id={`popover-${provisionAnswer.provisionName}`} className='d-inline-block'>
          {provisionAnswer.userAnswer || provisionAnswer.modelAnswer || 'N/A'}
        </span>
      </td>
      <td>
        <i className='mdi mdi-pencil font-size-18 success' onClick={toggleModal} />
        <Modal size='lg' isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Reviewing {provisionAnswer.provisionLabel}</ModalHeader>
          <ModalBody className=''>
            <div>
              <strong>Provision value from PDAI model:</strong> <br></br>
              <div className='modal-body-feedback'>{originalValue}</div>{' '}
              {/* Wrap original value in a container */}
            </div>
            <br></br>
            <strong>Correct provision value:</strong> <br></br>
            <textarea // Use textarea for multiline editing
              className='modal-body-feedback'
              value={editedValue}
              onChange={(e) => setEditedValue(e.target.value)}
              rows={5}
            />
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' size='sm' onClick={handleCancel}>
              Cancel
            </Button>
            <Button color='warning' size='sm' onClick={handleReset}>
              Reset
            </Button>
            <Button color='primary' size='sm' onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </td>
    </tr>
  );
};

export default ProvisionRow;
