import Breadcrumb from 'Components/Common/Breadcrumb';
import React from 'react';
import { Container } from 'reactstrap';

const Dashboard = () => {
  document.title = 'Dashboards | Plan Data AI';

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Dashboards' breadcrumbItem='Dashboards' />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
