//REGISTER
export const POST_FAKE_REGISTER = '/post-fake-register';

//LOGIN
export const POST_FAKE_LOGIN = '/post-fake-login';
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login';
export const POST_FAKE_PASSWORD_FORGET = '/fake-forget-pwd';
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd';
export const SOCIAL_LOGIN = '/social-login';

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile';
export const POST_EDIT_PROFILE = '/post-fake-profile';

// Calendar
export const GET_EVENTS = '/events';
export const GET_CATEGORIES = '/categories';
export const ADD_NEW_EVENT = '/add/event';
export const UPDATE_EVENT = '/update/event';
export const DELETE_EVENT = '/delete/event';

// Chat
export const GET_MESSAGES = '/messages';
export const ADD_MESSAGE = 'add/message';
export const DELETE_MESSAGE = 'delete/message';

// Ecommerce
// Product
export const GET_PRODUCTS = '/apps/product';
export const GET_PRODUCTS_DETAIL = '/apps/productDetail';

// Orders
export const GET_ORDERS = '/apps/order';
export const ADD_NEW_ORDER = '/apps/order';
export const UPDATE_ORDER = '/apps/order';
export const DELETE_ORDER = '/apps/order';

// Customers
export const GET_CUSTOMERS = '/apps/customer';
export const ADD_NEW_CUSTOMER = '/apps/customer';
export const UPDATE_CUSTOMER = '/apps/customer';
export const DELETE_CUSTOMER = '/apps/customer';

//SHOPS
export const GET_SHOPS = '/shops';

//cart

export const GET_CART = '/cart';
export const DELETE_CART = '/delete-cart';

//CRYPTO
export const GET_WALLET = '/wallet';
//activities
export const GET_WALLET_ACTIVITIES = 'wallet-activies';

//PROJECTS
export const GET_PROJECTS = '/projects';
export const GET_PROJECT_DETAIL = '/projects';
export const UPDATE_PROJECT = '/update/project';
export const DELETE_PROJECT = '/delete/project';

// Task
export const GET_TASKS = '/apps/task';
export const ADD_TASKS = '/add/task';
export const UPDATE_TASKS = '/update/task';
export const DELETE_TASKS = '/delete/task';

//CONTACTS
export const GET_USERS = '/apps/users';
export const ADD_NEW_USERS = '/apps/users';
export const UPDATE_USERS = '/apps/users';
export const DELETE_USERS = '/delete/users/';
export const GET_USER_PROFILE = '/user';

// Contact
export const GET_CONTACTS = '/apps/contact';

// Crypto
export const GET_CRYPTO_ORDRER_LIST = '/crypto-order-list';

// Invoice
export const GET_INVOICES = '/apps/invoice';
export const GET_INVOICE_DETAIL = '/apps/invoice-detail';

//Mails
export const GET_INBOX_MAILS = '/mailslists';
export const SELECT_FOLDER = '/folders';
export const GET_SELECTE_MAIL = '/seelctMail';
export const UPDATE_MAIL = '/update/mail';
export const SET_FOLDER_SELECTED_MAILS = '/setfolderonmail';
export const STARED_MAIL = '/stared/mail';
export const TRASH_MAIL = '/trash/mail';
export const GET_MAILS_ID = '/mail:id';

//Delete mail
export const DELETE_MAIL = '/mail/delete';

export const GET_EARNING_DATA = '/earning-charts-data';

export const TOP_SELLING_DATA = '/top-selling-data';

//dashboard charts data
export const GET_DASHBOARD_EMAILCHART = '/dashboard/email-chart';

//latest transaction
export const GET_TRANSACTION = '/transaction';

// Wallet Balance
export const GET_WALLENT_BALANCE = '/walletBalance';

// Visitors
export const GET_BLOG_VISITORS = '/blogVisitors';

//statistics Applications
export const GET_STATISTICS_APPLICATION = '/statistics Applications';

//Jobs
export const GET_JOB_LIST = '/jobs';
export const DELETE_JOB_LIST = '/delete/job';
export const ADD_NEW_JOB_LIST = '/job/add';
export const UPDATE_JOB_LIST = '/job/update';

//job grid
export const GET_JOB_GRID = '/job-grid';
//job condidate list
export const GET_CANDIDATE0_LIST = '/condidate-list';

//Apply Jobs
export const GET_APPLY_JOB = '/jobApply';
export const DELETE_APPLY_JOB = '/delete/applyjob';

//Chat
export const GET_CHATS = '/chats';
export const GET_GROUPS = '/groups';
