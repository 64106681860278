import { getFirebaseBackend } from '../../../firebase';
import { userForgetPasswordError, userForgetPasswordSuccess } from './reducer';

export const userForgetPassword = (user, history) => async (dispatch) => {
  try {
    const data = await getFirebaseBackend().forgetPassword(user.email);
    if (data) {
      dispatch(
        userForgetPasswordSuccess('Reset link are sended to your mailbox, check there first'),
      );
    }
  } catch (forgetError) {
    dispatch(userForgetPasswordError(forgetError));
  }
};
