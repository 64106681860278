import React, { useEffect, useState } from 'react';

// Redux
import { Link } from 'react-router-dom';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';

// Formik validation
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

// import images

//import thunk
import { loginuser, resetLoginMsgFlag, socialLogin } from 'slices/auth/login/thunk';

import withRouter from 'Components/Common/withRouter';
import { createSelector } from 'reselect';

const Login = (props: any) => {
  const [show, setShow] = useState(false);
  const dispatch: any = useDispatch();

  //meta title
  document.title = 'Login | Plan Data AI';

  const selectProperties = createSelector(
    (state: any) => state.Login,
    (login) => ({
      error: login.error,
    }),
  );

  const { error } = useSelector(selectProperties);

  // Form validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: (values: any) => {
      dispatch(loginuser(values, props.router.navigate));
    },
  });

  const signIn = (type: any) => {
    dispatch(socialLogin(type, props.router.navigate));
  };

  const socialResponse = (type: any) => {
    signIn(type);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginMsgFlag());
      }, 5000);
    }
  }, [dispatch, error]);

  const handleContactUsClick = () => {
    window.location.href = 'mailto:support@plandataai.com';
  };

  return (
    <React.Fragment>
      <div className='account-pages my-5 pt-sm-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='overflow-hidden'>
                <div className='bg-primary-subtle'>
                  <Row>
                    <Col className='col-7'>
                      <div className='text-primary p-3 pl-4'>
                        <h5 className='text-primary'>Welcome Back !</h5>
                        <p>Sign in to continue to Plan Data AI.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className='pt-4'>
                  <div className='auth-logo'></div>
                  <div className='p-2'>
                    <Form
                      className='form-horizontal'
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className='mb-3'>
                        {error ? <Alert color='danger'>{error}</Alert> : null}
                        <Label className='form-label'>Email</Label>
                        <Input
                          name='email'
                          className='form-control'
                          placeholder='Enter email'
                          type='text'
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type='invalid'>{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className='mb-3'>
                        <Label className='form-label'>Password</Label>
                        <div className='input-group auth-pass-inputgroup'>
                          <Input
                            name='password'
                            value={validation.values.password || ''}
                            type={show ? 'text' : 'password'}
                            placeholder='Enter Password'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <button
                            onClick={() => setShow(!show)}
                            className='btn btn-light '
                            type='button'
                            id='password-addon'
                          >
                            <i className='mdi mdi-eye-outline'></i>
                          </button>
                        </div>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type='invalid'>{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className='form-check'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id='customControlInline'
                        />
                        <label className='form-check-label' htmlFor='customControlInline'>
                          Remember me
                        </label>
                      </div>

                      <div className='mt-3 d-grid'>
                        <button className='btn btn-primary btn-block ' type='submit'>
                          Log In
                        </button>
                      </div>

                      <div className='mt-4 text-center'>
                        <h5 className='font-size-14 mb-3'>Sign in with</h5>

                        <ul className='list-inline'>
                          <li className='list-inline-item'>
                            <Link
                              to='#'
                              className='social-list-item bg-danger text-white border-danger'
                              onClick={(e: any) => {
                                e.preventDefault();
                                socialResponse('google');
                              }}
                            >
                              <i className='mdi mdi-google' />
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className='mt-4 text-center'>
                        <Link to='/forgot-password' className='text-muted'>
                          <i className='mdi mdi-lock me-1' /> Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className='mt-5 text-center'>
                <p>
                  Don't have an account ?{' '}
                  <span
                    onClick={handleContactUsClick}
                    className='fw-medium text-primary cursor-pointer' // Add cursor-pointer for visual feedback
                  >
                    Contact us
                  </span>{' '}
                </p>
                <p>© {new Date().getFullYear()} PDAI.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
