import { AdoptionAgreement } from '@helpers/firebase_helper';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFirebaseBackend } from '../../firebase';

// List all adoption agreements
export const listAllAdoptionAgreements = createAsyncThunk(
  'adoptionAgreements/listAll',
  async (): Promise<AdoptionAgreement[]> => {
    const response = getFirebaseBackend().listAllAdoptionAgreements();
    return response;
  },
);

// List all adoption agreement IDs
export const listAllAdoptionAgreementIds = createAsyncThunk(
  'adoptionAgreements/listAllIds',
  async (): Promise<string[]> => {
    const response = getFirebaseBackend().listAllAdoptionAgreementsIds();
    return response;
  },
);

// List adoption agreements by status
export const listAdoptionAgreementsByStatus = createAsyncThunk(
  'adoptionAgreements/listByStatus',
  async (status: string): Promise<AdoptionAgreement[]> => {
    const response = getFirebaseBackend().listByStatus(status);
    return response;
  },
);

// Update an adoption agreement
export const updateAdoptionAgreement = createAsyncThunk(
  'adoptionAgreements/update',
  async ({
    id,
    updatedData,
  }: {
    id: string;
    updatedData: Partial<AdoptionAgreement>;
  }): Promise<Partial<AdoptionAgreement>> => {
    const response = getFirebaseBackend().updateAdoptionAgreement(id, updatedData);
    return response;
  },
);

// Update an adoption agreement
export const selectAdoptionAgreement = createAsyncThunk(
  'adoptionAgreements/select',
  async (selectedId: string): Promise<string> => {
    return selectedId;
  },
);
