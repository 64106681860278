import { getFirebaseBackend } from '../../../firebase';
import { postFakeRegister, postJwtRegister } from '../../../helpers/fakebackend_helper';

// action
import {
  apiErrorChange,
  registerUserFailed,
  registerUserSuccessful,
  resetRegisterFlagChange,
} from './reducer';

// Is user register successfull then direct plot user in redux.
export const registerUser = (user: any) => async (dispatch: any) => {
  try {
    let response;

    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      response = getFirebaseBackend().registerUser(user.email, user.password);
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      response = postJwtRegister('/post-jwt-register', user);
    } else if (process.env.REACT_APP_DEFAULTAUTH) {
      response = postFakeRegister(user);
      const data: any = await response;
      dispatch(registerUserSuccessful(data));
    }
  } catch (error) {
    dispatch(registerUserFailed(error));
  }
};

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};

export const apiError = () => {
  try {
    const response = apiErrorChange('');
    return response;
  } catch (error) {
    return error;
  }
};
