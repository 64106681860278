import React from 'react';
import { Link } from 'react-router-dom';

//import components
import SidebarContent from './SidebarContent';

//import images

const Sidebar = (props: any) => {
  return (
    <React.Fragment>
      <div className='vertical-menu'>
        <div className='navbar-brand-box pt-4'>
          <Link to='/' className='logo-temp'>
            PlanDataAI
          </Link>
        </div>
        <div data-simplebar className='h-100'>
          {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className='sidebar-background'></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
