import { ProvisionAnswer } from '@pdai/shared';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import ProvisionRow from './provision-row';

interface ViewAnswersModalProps {
  isOpen: boolean;
  toggle: () => void;
  provisionsAnswers: ProvisionAnswer[];
}

const ViewAnswersModal: React.FC<ViewAnswersModalProps> = ({
  isOpen,
  toggle,
  provisionsAnswers,
}) => {
  const categories = [
    'Plan',
    'Sponsor/Employer',
    'Minimum Age and Service Requirements',
    'Contributions',
    'Annual Compliance',
    'Retirement',
    'Vesting and Forfeitures',
    'Distribution',
    'Loan',
  ];
  const onSaveFeedback = () => {};

  return (
    <Modal size='lg' isOpen={isOpen} toggle={toggle} className='modal-answers'>
      <ModalHeader toggle={toggle}>Provisions</ModalHeader>
      <ModalBody>
        <div className='table-responsive'>
          {categories.map((category) => (
            <Table className=' mb-0'>
              <thead>
                <tr>
                  <th>{category}</th>
                  <th className='answer-column'></th>
                </tr>
              </thead>
              <tbody>
                {provisionsAnswers?.map((provision) => {
                  if (provision.category === category) {
                    return <ProvisionRow provisionAnswer={provision} onSave={onSaveFeedback} />;
                  }
                  return null;
                })}
              </tbody>
            </Table>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewAnswersModal;
