import React from 'react';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';

//redux
import { useDispatch, useSelector } from 'react-redux';

import withRouter from 'Components/Common/withRouter';
import { Link } from 'react-router-dom';

// Formik Validation
import { useFormik } from 'formik';
import * as Yup from 'yup';

// action
import { userForgetPassword } from '../../slices/thunk';

// import images
import { createSelector } from 'reselect';

const ForgetPasswordPage = (props) => {
  //meta title
  document.title = 'Forget Password | Plan Data AI';

  const dispatch = useDispatch<any>();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history));
    },
  });

  const selectProperties = createSelector(
    (state: any) => state.ForgetPassword,
    (forgetPassword) => ({
      forgetError: forgetPassword.forgetError,
      forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
    }),
  );

  const { forgetError, forgetSuccessMsg } = useSelector(selectProperties);

  return (
    <React.Fragment>
      <div className='home-btn d-none d-sm-block'>
        <Link to='/' className='text-dark'>
          <i className='bx bx-home h2' />
        </Link>
      </div>
      <div className='account-pages my-5 pt-sm-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='overflow-hidden'>
                <div className='bg-primary-subtle bg-softbg-soft-primary'>
                  <Row>
                    <Col xs={12}>
                      <div className='text-primary p-4'>
                        <h5 className='text-primary'>Forgot Password ?</h5>
                        <p>Provide your email for password reset instructions.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className='pt-4'>
                  <div className='p-2'>
                    {forgetError && forgetError ? (
                      <Alert color='danger' style={{ marginTop: '13px' }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color='success' style={{ marginTop: '13px' }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className='form-horizontal'
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className='mb-3'>
                        <Label className='form-label'>Email</Label>
                        <Input
                          name='email'
                          className='form-control'
                          placeholder='Enter email'
                          type='email'
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type='invalid'>{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className='mb-3'>
                        <Col className='text-end'>
                          <button className='btn btn-primary w-md ' type='submit'>
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className='mt-5 text-center'>
                <p>
                  Go back to{' '}
                  <Link to='/login' className='font-weight-medium text-primary'>
                    Login
                  </Link>{' '}
                </p>
                <p>© {new Date().getFullYear()} PDAI.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgetPasswordPage);
