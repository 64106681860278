import { Navigate } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';

// Auth
import AdoptionAgreement from 'pages/AdoptionAgreement';
import ForgotPassword from 'pages/Authentication/ForgotPassword';
import Login from 'pages/Authentication/login';
import Logout from 'pages/Authentication/Logout';
import SignUp from 'pages/Authentication/Register';
import UserProfile from 'pages/Authentication/user-profile';

const authProtectedRoutes = [
  { path: '/adoption-agreement', component: <AdoptionAgreement /> },
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/profile', component: <UserProfile /> },

  { path: '/', exact: true, component: <Navigate to='/adoption-agreement' /> },
];

const publicRoutes = [
  { path: '/login', component: <Login /> },
  { path: '/logout', component: <Logout /> },
  { path: '/forgot-password', component: <ForgotPassword /> },
  { path: '/register', component: <SignUp /> },
];
export { authProtectedRoutes, publicRoutes };
